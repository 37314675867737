import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import quantReducer from "./quantitative/quantSlice";
import qualitativeReducer from "./qualitative/qualitativeSlice";
import queryReducer from "./query/querySlice";
import wealthAdvisorSlice from "./wealthAdvisor/wealthAdvisorSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    quantitative: quantReducer,
    qualitative: qualitativeReducer,
    query: queryReducer,
    wealthAdvisor: wealthAdvisorSlice,
  },
});

export default store;
