import React from "react";
import { makeStyles } from "@mui/styles";
import FeatureItem from "./FeatureItem";

const useStyles = makeStyles((theme) => ({
  listSelected: {
    backgroundColor: "#3a3a3a !important",
    //color: "inherit !important",
  },
  chilItemSelected: {
    color: "#ffb442 !important",
  },
  childList: {
    padding: "0 0 5px",
    "& >li": {
      paddingTop: "1px",
      paddingBottom: "1px",
      position: "relative",
      "& >.MuiButtonBase-root": {
        padding: "6px 30px",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#a7a7a7",
        "&:hover": {
          color: "#e1e1e1",
        },
      },
    },
  },
  sidebarTitle: {
    fontWeight: 500,
    marginTop: "10px",
    letterSpacing: "0.5px",
    fontSize: "15px",
    lineHeight: "23px",
    color: "#9b9b9b",
  },
}));

const FeatureList = ({ data }) => {
  const classes = useStyles();
  return data.map((item) => (
    <li key={item.id}>
      <FeatureItem itemObj={item} />
      {item.isChild && (
        <ul className={classes.childList}>
          {item.childList.map((subItem) => {
            return (
              <li key={subItem.id}>
                <FeatureItem itemObj={subItem} />
              </li>
            );
          })}
        </ul>
      )}
    </li>
  ));
};

export default FeatureList;
