import React, { useEffect, useState } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { makeStyles } from "@mui/styles";
import config from "../../config";

import clsx from "clsx";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { privateApiGET } from "../../components/PrivateRoute";
import Api from "../../components/Api";
import {
  setActivePageID,
  disableInitialLoading,
  setProfileData,
  setBotSettingsData,
  setBotSettingsLoading,
} from "../../redux/app/appSlice";
import { filterMenuItemsByModes, MenuItems } from "../../menu-items";
import { validateObjectData } from "../../utils";
import CustomDialog from "../../components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: "85px 20px 135px 310px",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.noSidebar": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const MainLayout = ({}) => {
  const { isSidebarOpened, initialAppLoading, isBotSettingLoading } =
    useSelector((state) => state.app);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Profile API comes here & initial loading and settting up pages happen here
  const handleFetchProfileData = () => {
    privateApiGET(Api.profile)
      .then((response) => {
        const { data, status } = response;
        console.log("Profile Data ---> ", data);
        if (status === 200) {
          dispatch(disableInitialLoading(false));
          dispatch(setProfileData(data?.data));

          // Check modes and redirect to first mode
          const modes = data?.data?.features?.bot_query_modes;
          const queryModes = filterMenuItemsByModes(modes);
          const redirectUrl = queryModes.length
            ? queryModes[0].url
            : config.defaultPath;
          navigate(redirectUrl);
        } else {
          //handleFetchProfileError();
        }
      })
      .catch((error) => {
        console.log("Profile API Error ----> ", error);
        handleFetchProfileError(error);
      });
  };

  // Handle Profile Error
  const handleFetchProfileError = (error) => {
    const { status } = error?.response || {};

    dispatch(disableInitialLoading(false));
    localStorage.removeItem("botSettings");
    localStorage.removeItem("token");
    dispatch(setBotSettingsData({}));
    dispatch(setBotSettingsLoading(!isBotSettingLoading));
    navigate("/login");
  };

  useEffect(() => {
    handleFetchProfileData();
  }, []);

  useEffect(() => {
    const tempArr = [];
    const menuArr = Object.values(MenuItems).flat();
    menuArr.map((item) => {
      if (item.isChild) {
        tempArr.push(item);
        item.childList.map((subItem) => tempArr.push(subItem));
      } else {
        tempArr.push(item);
      }
    });

    const currMenuItem = tempArr.filter((obj) => obj.url === location.pathname);
    dispatch(setActivePageID(currMenuItem[0].id));
  }, [location]);

  const classes = useStyles();
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div className={classes.content}>
          <Navbar />
          <Sidebar />

          <div
            className={clsx({
              [classes.mainSection]: true,
              noSidebar: !isSidebarOpened,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
