import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// defaultTheme
import theme from "./theme";

// routing
import Routes from "./routes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setBotSettingsData,
  setBotSettingsLoading,
} from "./redux/app/appSlice";
import SettingsLoader from "./SettingsLoader";
import Api from "./components/Api";
import { validateObjectData } from "./utils";
import { defaultBotSettings } from "./constants";

const App = () => {
  const botSettings = localStorage.getItem("botSettings");
  const { isBotSettingLoading, botSettingsData } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();

  const handleFetchBotSettings = () => {
    axios({
      method: "GET",
      url: Api.botSettings,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const { status, data } = response;
        if (status == 200) {
          console.log("Fetch BOT Settings Response ---->", data);
          const dataObj = data?.data;
          const dataAsString = JSON.stringify(dataObj);
          setTimeout(() => {
            dispatch(setBotSettingsData(dataObj));
            localStorage.setItem("botSettings", dataAsString);
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch(setBotSettingsData(defaultBotSettings));
        localStorage.setItem("botSettings", JSON.stringify(defaultBotSettings));
        console.log("Fetch BOT Settings ERROR ---->", error);
      });
  };

  useEffect(() => {
    if (botSettings === "undefined" || botSettings == null) {
      handleFetchBotSettings();
    } else {
      dispatch(setBotSettingsData(JSON.parse(botSettings)));
    }
  }, [isBotSettingLoading]);

  return (
    <>
      {botSettings === "undefined" || botSettings == null ? (
        <SettingsLoader />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme()}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export default App;
