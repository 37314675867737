export default {
  // Bot Settings
  botSettings: `${process.env.REACT_APP_API_BASEURL}/base/settings/app-settings/`,

  // Auth endpoints
  login: `${process.env.REACT_APP_API_BASEURL}/base/users/login/`,
  logout: `/base/users/logout/`,
  profile: "/base/users/profile/",

  forgotPassword: "/base/v1/user/forgot-password/",
  resetPassword: "/base/v1/user/reset-password/",

  // Channel Info
  channelInfo: `/app/${process.env.REACT_APP_CLIENT_NAME}/ws/chat_channel_info/`,

  // Websocket URL
  ws: `${process.env.REACT_APP_SOCKET_PROTOCAL}://${process.env.REACT_APP_DOMAIN}/app/${process.env.REACT_APP_CLIENT_NAME}/ws/ongil_chat/`,
};
