import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CampaignIcon from "@mui/icons-material/Campaign";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { botID } from "../constants";

export const QNChildItems = [
  // {
  //   id: "home",
  //   title: "Query",
  //   url: "/quant-analysis/query",
  //   icon: HomeIcon,
  // },
  {
    id: "faq",
    title: "FAQ",
    url: "/quantitative-analysis/faq",
    icon: AnalyticsIcon,
  },
  {
    id: "interactive",
    title: "Interactive Mode",
    url: "/quantitative-analysis/interactive",
    icon: AssessmentIcon,
  },
  {
    id: "insights",
    title: "Insights",
    url: "/quantitative-analysis/insights",
    icon: CampaignIcon,
  },
];

// Quantitative Menus
export const QNMenuItems = [
  {
    id: botID.STRUCTURED,
    title: "Quant. Analysis",
    isChild: false,
    childList: QNChildItems,
    url: "/quantitative-analysis",
  },
];

// Qualitative Menus
export const QLMenuItems = [
  {
    id: botID.UN_STRUCTURED,
    title: "Quali. Analysis",
    url: "/qualitative-analysis",
    isChild: false,
    childList: [],
  },
];

// Azure Bot
export const AZMenuItems = [
  {
    id: botID.QUERY,
    title: "ESG",
    url: "/esg",
    isChild: false,
    childList: [],
  },
];

// Wealth Advisor
export const WAMenuItems = [
  {
    id: botID.WEALTH_ADVISOR,
    title: "Wealth Advisor",
    url: "/wealth-advisor",
    isChild: false,
    childList: [],
  },
];

export const MenuItems = {
  bot: AZMenuItems,
  wealthAdvisor: WAMenuItems,
  structured: QNMenuItems,
  unstructured: QLMenuItems,
};

export const filterMenuItemsByModes = (modes) => {
  const tempArr = [];
  if (modes && modes.length) {
    const menuArr = Object.values(MenuItems).flat();

    menuArr.map((item) => {
      if (modes.includes(item.id)) {
        tempArr.push(item);
      }
    });
  }

  return tempArr;
};
