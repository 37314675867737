import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";

// Auth Pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));

// View Pages
// const QuantAnalysisPage = Loadable(
//   lazy(() => import("../views/QuantAnalysis/quant.page"))
// );
// const FAQPage = Loadable(lazy(() => import("../views/FAQ/faq.page")));

// const QualiAnalysisPage = Loadable(
//   lazy(() => import("../views/QualiAnalysis/quali.page"))
// );

const QueryBotPage = Loadable(
  lazy(() => import("../views/QueryBot/query.page"))
);

const WealthAdvisorBot = Loadable(
  lazy(() => import("../views/WealthAdvisorBot/wealthadvisor.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* Main Routes */}
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />
      <Route
        path="esg"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QueryBotPage />} />
      </Route>

      <Route
        path="wealth-advisor"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<WealthAdvisorBot />} />
      </Route>

      {/* <Route
        path="quantitative-analysis"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QuantAnalysisPage />} />
        <Route path="faq" element={<FAQPage />} />
      </Route>

      <Route
        path="qualitative-analysis"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QualiAnalysisPage />} />
      </Route> */}

      {/* Auth Routes */}
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default AppRoutes;
